<template>
    <component v-if="state.options?.component && state.render" ref="componentRef" :is="state.options.component" v-bind="state.options.props" :class="{show: state.show}"></component>
</template>

<script setup lang="ts">
import type GlobalHoverService from 'o365.controls.GlobalHoverService.ts';
import { createPopper } from 'popper';
import { inject, watch, onMounted, ref, nextTick } from 'vue';

const HoverService: GlobalHoverService = inject('GlobalHoverService');

const service = HoverService.getService();
const state = service.state;
const componentRef = ref(null);
let popperInstance = null;

/*
watch(() => state.activeUid, (newUid) => {
    console.log
    if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
    }
    nextTick().then(() => {
        const el = service.getElementFromUid(state.activeUid);
        if (el) { initPopper(el); }
    });
});
*/

let popperDestroyDebounce = null;
watch(() => state.show, (show) => {
    if (show) {
        const el = service.getElementFromUid(state.activeUid);
        if (el) { initPopper(el); }
    } else {
        if (typeof componentRef.value?.hoverHide === 'function') { componentRef.value.hoverHide(); }
        popperDestroyDebounce = window.setTimeout(() => {
            destroyPopper()
            popperDestroyDebounce = null;
        }, 151);
    }
});

function destroyPopper() {
    if (popperInstance == null) { return; }

    popperInstance.destroy();
    popperInstance = null;
}

function initPopper(el: HTMLElement) {
    if (popperDestroyDebounce && popperInstance) { window.clearTimeout(popperDestroyDebounce); popperDestroyDebounce = null; destroyPopper(); }
    if (typeof componentRef.value?.hoverShow === 'function') { componentRef.value.hoverShow(); }
    popperInstance = createPopper(el, componentRef.value.hoverElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [35, 0],
                },
            },
        ],
    });
    service._initPopperEvents(componentRef.value.hoverElement);
}

onMounted(() => {
    if (state.render && state.options?.component) { 
        const el = service.getElementFromUid(state.activeUid);
        if (el) { initPopper(el); }
    }
});
</script>